import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faDatabase } from '@fortawesome/pro-regular-svg-icons/faDatabase';
import { faDiamond } from '@fortawesome/pro-regular-svg-icons/faDiamond';
import { faFileMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faFileMagnifyingGlass';
import { faMerge } from '@fortawesome/pro-regular-svg-icons/faMerge';
import { faTable } from '@fortawesome/pro-regular-svg-icons/faTable';
import { faTableList } from '@fortawesome/pro-regular-svg-icons/faTableList';
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers';
import { TreeItem, TreeItemIndex } from 'react-complex-tree';

import { colors } from 'quotient/theme/foundations/colors/colors';

import { NavigatorWorksheet, NavigatorDataObject, WORKSHEET_TYPES } from './types';

type FormattedTreeData = Record<TreeItemIndex, TreeItem<NavigatorDataObject>>;

const componentColors: string[] = ['secondary.600', 'tertiary.600', 'quaternary.600', 'primary.600'];

const componentColorMap = new Map();

type DataObjectWithComponentInstance = {
  component_instance_id?: string;
  component_instance?: { [key: string]: string };
};

const getComponentColorForWorksheet = (worksheet: NavigatorWorksheet): string => {
  if (!worksheet.component_instance_id) {
    return 'none';
  }
  if (!componentColorMap.has(worksheet.component_instance_id)) {
    const colorIndex = componentColorMap.size % componentColors.length;
    componentColorMap.set(worksheet.component_instance_id, componentColors[colorIndex]);
    return componentColors[colorIndex];
  }
  if (componentColorMap.has(worksheet.component_instance_id)) {
    return componentColorMap.get(worksheet.component_instance_id);
  }
  return 'none';
};

export const cacheColors = (navigatorObjects: DataObjectWithComponentInstance[]) => {
  /** We will pre-populate the componentColorMap based on a given a set of data objects */
  navigatorObjects.forEach((obj, index) => {
    if (!obj.component_instance_id && !obj.component_instance) {
      return;
    }

    const componentInstanceId = obj.component_instance_id || obj.component_instance?.id;
    if (!componentInstanceId) {
      return;
    }

    if (!componentColorMap.has(componentInstanceId)) {
      const color = componentColors[index % componentColors.length];
      componentColorMap.set(componentInstanceId, color);
    }
  });

  return componentColorMap;
};

export const getCachedComponentColorMapInHexCode = () => {
  return Array.from(componentColorMap.entries()).reduce((acc, [key, val]) => {
    const colorKeys = val.split('.');
    const hexColor = (colors[colorKeys[0] as keyof typeof colors] as any)[colorKeys[1]];
    return {
      ...acc,
      [key]: hexColor,
    };
  }, {});
};

export const getTreeData = (worksheets: NavigatorWorksheet[]): FormattedTreeData => {
  const formattedTreeData: FormattedTreeData = {
    root: {
      index: 'root',
      isFolder: true,
      children: [],
      data: {
        object: 'root',
        id: 'root',
        name: 'root',
        description: 'root',
        type: 'root',
        is_hidden: false,
      },
    },
  };

  worksheets.forEach((worksheet: NavigatorWorksheet) => {
    const componentGroupColor = getComponentColorForWorksheet(worksheet);
    formattedTreeData[worksheet.id] = {
      index: worksheet.id,
      isFolder: false,
      children: [],
      data: {
        ...worksheet,
        componentGroupColor,
      },
    };
    formattedTreeData.root.children?.push(worksheet.id);
  });

  return formattedTreeData;
};

const worksheetIconTypes: Record<WORKSHEET_TYPES, IconDefinition> = {
  component_instance: faDiamond,
  derived: faMerge,
  api: faTable,
  raw: faDatabase,
  payout_summary: faTable,
  period_group: faTable,
  hierarchy: faUsers,
  historical_detailed: faFileMagnifyingGlass,
  historical_payouts: faTable,
  output: faTable,
  quota: faTable,
  data_flow: faTable,
  unified_employee_assumption: faTable,
  master: faTable,
  attribute: faTable,
  report_model: faTable,
};

export const getWorksheetTypeIcon = (type: WORKSHEET_TYPES, isComponentInstance = false) => {
  return isComponentInstance
    ? worksheetIconTypes[WORKSHEET_TYPES.COMPONENT_INSTANCE]
    : worksheetIconTypes[type] || faTableList;
};
